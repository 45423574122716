import React, { Component } from "react";
// libraries
import moment from "moment";
import { ApiList } from "../../config/apiList";

import * as utils from "../common/utils";

// custom
import { Line } from "react-chartjs-2";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import Lookup from "../../config/lookup";

import API from "../../config/api";

const chartKeys24 = Array(23).fill().map((element, index) => `${index + 0}`)
const chartKeysAM = Array(12).fill().map((element, index) => `${index + 0}`)
const chartKeysPM = Array(12).fill().map((element, index) => `${index + 12}`)

const chartTabWeekday = 'weekday'
const chartTabDaily = 'daily'
const chartTabHourly = 'hourly'

const daynames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

const heatMapTheme = {
    axis: {
        fontSize: "14px",
        tickColor: "#eee",
        ticks: {
            line: {
                stroke: "#555555",
            },
            text: {
                fill: "#aaaaaa",
            },
        },
        legend: {
            text: {
                fill: "#aaaaaa",
            },
        },
    },
    grid: {
        line: {
            stroke: "#555555",
        },
    },
    tooltip: {
        container: {
            background: "#000000",
            color: "#FFF",
        },
    },
};

class BottomSortCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayLabel: "",
            labels: [],
            data: [],
            heatChartData: [],
            chartTab: chartTabWeekday,
            weekdayChartData: [],
            chartKeys: chartKeys24,
            isMultiFlag: false,
            datasets: [],
            bgColor: [
                "rgb(91, 202, 221)",
                "rgb(166, 224, 206)",
                "rgb(255, 152, 120)",
                "rgb(58, 180, 73)",
                "rgb(234, 86, 124)",
            ],
            preGradient: [
                "rgba(91, 202, 221,0.2)",
                "rgba(166, 224, 206,0.2)",
                "rgba(255, 152, 120,0.2)",
                "rgba(58, 180, 73,0.2)",
                "rgba(234, 86, 124,0.2)",
            ],
            limitExccedFlag: false,
        };
    }

    componentDidMount = () => {
        this.setState(
            {
                datasets: [],
            },
            () => {
                this.onChartSelected(this.state.chartTab)
            }
        );
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState(
                {
                    datasets: [],
                },
                () => {
                    this.onChartSelected(this.state.chartTab, true)
                }
            );
        }
    }

    replaceUrlParam(url, paramName, paramValue) {
        let href = new URL(url);
        href.searchParams.set(paramName, paramValue);
        return href.toString();
    }

    getStatisticsDaily = () => {
        if (this.props.multi !== "brands") {
            this.getStatisticsDailySingleLine();
            return
        }

        if (this.props.productsFilter.length === 0) {
            return
        }

        const URL = `${ApiList.env}${ApiList.endpoint.statistics}daily?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

        this.setState({
            datasets: [],
            labels: [],
            displayLabel: '',
            limitExccedFlag:
                this.props.productsFilter.length > 5
                    ? true
                    : false,
        });

        this.props.productsFilter.forEach((productName, i) => {
            if (i < 5) {
                this.getStatisticsDailyMulti("products", productName, URL);
            }
        });
    };

    getStatisticsDailySingleLine = async () => {
        const URL = `${ApiList.env}${ApiList.endpoint.statistics}daily?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

        const data = await API.call(URL)

        let xData = [];
        let yData = [];

        data.forEach((item, key) => {
            if (key < 7) {
                xData.push(item.count ? item.count : 0);
                yData.push(
                    item.date ? moment(item.date).format("DD/MM") : 0
                );
            }
        });

        let temp = [
            {
                label: ":",
                data: xData,
            },
        ];

        this.setState({
            datasets: temp,
            labels: yData,
        });
    };

    getStatisticsDailyMulti = async (queryName, queryValue, URL) => {
        URL += `&${queryName}=${encodeURIComponent(queryValue)}`;
        const data = await API.call(URL)

        let xData = [];
        let yData = [];

        data.forEach((item, key) => {
            if (key < 7) {
                xData.push(item.count ? item.count : 0);
                yData.push(item.date ? moment(item.date).format("DD") : 0);
            }
        });

        let temp = {
            label: queryValue,
            data: xData,
        };

        let tempArr = this.state.datasets;

        tempArr.push(temp);

        this.setState({
            datasets: tempArr,
            labels: yData,
            displayLabel: queryName,
        });
    };

    getStatisticsWeekday = async () => {
        const URL = `${ApiList.env}${ApiList.endpoint.statistics}dayhour?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

        const data = await API.call(URL)

        let weekDays = {}

        daynames.forEach(day => {
            weekDays[day] = Array(24).fill(0)
        })

        data.forEach((item) => {
            const weekday = item.day.split("-")[0];
            const hour = parseInt(item.day.split("-")[1], 10)
            weekDays[weekday][hour] = item.count;
        });

        /*
        weekDays -> {
            mon -> [ hourly indexed count ], 
            tue -> [ 1, 0, 1000, ...] 24 items each row
            ...
        }
        */

        let result = [];

        daynames.forEach((key) => {
            const array = weekDays[key];
            let obj = {};
            obj[key] = array.map((N, I) => {
                return { hour: I, count: N };
            });
            result.push(obj);
        });

        /**
         * result -> [ {mon: [ {hour: , count: } ]}  ]
        */
        let convertToHeatData = [];
        result
            .forEach((itemValue, i) => {
                let date = Object.keys(itemValue);
                let tempHold = {};
                itemValue[date].forEach((itemhour) => {
                    tempHold = {
                        ...tempHold,
                        [itemhour.hour]: itemhour.count,
                    };
                });
                tempHold = { ...tempHold, date: date.toString() };
                convertToHeatData.push(tempHold);
            });
        this.setState({
            weekdayChartData: convertToHeatData,
        });

        console.log("update weekday data")
    }

    getStatisticsHourly = async () => {
        const URL = `${ApiList.env}${ApiList.endpoint.statistics}dailyhourly?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

        const data = await API.call(URL)

        let monthDays = {};

        data.forEach((item) => {
            const m = moment(item.dateHour);
            let monthDay = m.format("MMM DD");
            let hour = m.hour();

            let monthDayArr = monthDays[monthDay];

            if (!monthDayArr) {
                monthDayArr = Array(24).fill(0);
            }

            monthDayArr[hour] = item.count;
            monthDays[monthDay] = monthDayArr;
        });

        const keys = Object.keys(monthDays);
        let result = [];
        let nth = parseInt(result.length / 10) || 1;
        keys.forEach((key) => {
            const array = monthDays[key];
            var obj = {};
            obj[key] = array.map(function (N, I) {
                return { hour: I, count: N };
            });
            result.push(obj);
        });

        let convertToHeatData = [];
        result
            .filter((e, i) => i % nth === nth - 1)
            .forEach((itemValue) => {
                let date = Object.keys(itemValue);
                let tempHold = {};
                itemValue[date].forEach((itemhour) => {
                    tempHold = {
                        ...tempHold,
                        [itemhour.hour]: itemhour.count,
                    };
                });
                tempHold = { ...tempHold, date: date.toString() };
                convertToHeatData.push(tempHold);
            });

        this.setState({
            heatChartData: convertToHeatData,
        });
    };

    amPmHandler = (event) => {
        let selected = event.target.value;
        if (selected === "am/pm") {
            this.setState({
                chartKeys: chartKeys24,
            });
        } else if (selected === "am") {
            this.setState({
                chartKeys: chartKeysAM,
            });
        } else if (selected === "pm") {
            this.setState({
                chartKeys: chartKeysPM,
            });
        }
    };

    renderDailyChart() {
        const { isMultiFlag, preGradient } = this.state;

        const lineOption = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: (tooltipItems, data) => {
                        return utils.formatter(
                            this.props.sortBy,
                            tooltipItems.yLabel
                        );
                    },
                },
            },
            animation: {
                easing: "easeInOutQuad",
                duration: 520,
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            color: "#464967",
                            display: false,
                            lineWidth: 1,
                            zeroLineColor: "#464967",
                        },
                        ticks: { padding: 10 },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            color: "#464967",
                            display: false,
                            lineWidth: 1,
                            zeroLineColor: "#464967",
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 3,
                            callback: (value, index, values) => {
                                return utils.formatter(
                                    this.props.sortBy,
                                    value
                                );
                            },
                        },
                    },
                ],
            },
            elements: {
                line: {
                    tension: isMultiFlag === false ? 0 : 0.4,
                },
                point: { radius: 4 },
            },
            legend: { display: false },
        };

        let dataColl = [];
        this.state.datasets.forEach((item, i) => {
            let dataset = {
                label: item.label,
                data: item.data,
                backgroundColor: preGradient[i],
                borderColor: this.state.bgColor[i],
            };
            dataColl.push(dataset);
        });

        const dataLine = {
            labels: this.state.labels,
            datasets: dataColl,
            borderColor: "#86367e",
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "#86367e",
            pointHoverBackgroundColor: "#86367e",
            pointHoverBorderColor: "rgba(134, 54, 126,0.5)",
            pointRadius: 4,
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: true,
            background: "rgba(134, 54, 126,0.5)",
        }

        return (
            <>
                <div className="card">
                    <div
                        className="card-body"
                        style={{ height: "400px" }}
                    >
                        {this.state.labels.length && (
                            <Line
                                data={dataLine}
                                options={lineOption}
                            />
                        )}
                    </div>

                    {this.state.datasets.length > 1 && (
                        <div className="card-footer">
                            <footer className="row">
                                {this.state.datasets
                                    .slice(0, 5)
                                    .map(
                                        (
                                            datasetsItems,
                                            datasetsKeys
                                        ) => {
                                            return (
                                                <small
                                                    className="col-3"
                                                    key={
                                                        datasetsItems.label +
                                                        Math.random(
                                                            0,
                                                            10
                                                        )
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            color: this
                                                                .state
                                                                .bgColor[
                                                                datasetsKeys
                                                            ],
                                                        }}
                                                    >
                                                        &#9673;
                                                    </span>{" "}
                                                    {
                                                        datasetsItems.label
                                                    }
                                                </small>
                                            );
                                        }
                                    )}
                            </footer>
                        </div>
                    )}
                </div>
            </>
        )
    }

    renderHeatMap(data, keys, indexBy) {
        return (
            <>
                <ResponsiveHeatMap
                    data={data}
                    keys={keys}
                    indexBy={indexBy}
                    margin={{
                        top: 0,
                        right: 0,
                        bottom: 50,
                        left: 50,
                    }}
                    colors="purples"
                    borderColor={{
                        from: "color",
                        modifiers: [
                            ["darker", 0.6],
                            ["opacity", 0.5],
                        ],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: "bottom",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: 36,
                    }}
                    axisLeft={{
                        orient: "left",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: -40,
                    }}
                    cellOpacity={1}
                    enableLabels={false}
                    labelTextColor={{
                        from: "color",
                        modifiers: [
                            ["darker", 1.8],
                        ],
                    }}
                    defs={[
                        {
                            id: "lines",
                            type: "patternLines",
                            background: "inherit",
                            color: "rgba(0, 0, 0, 0.1)",
                            rotation: -45,
                            lineWidth: 4,
                            spacing: 7,
                        },
                    ]}
                    fill={[{ id: "lines" }]}
                    animate={true}
                    motionStiffness={80}
                    theme={heatMapTheme}
                    motionDamping={9}
                    hoverTarget="cell"
                    cellHoverOthersOpacity={0.25}
                />
            </>
        )
    }

    onChartSelected(chartTab, reload = false) {
        console.log(`onchart selected: ${chartTab}`)

        this.setState({ chartTab: chartTab });

        switch (chartTab) {
            case chartTabWeekday:
                if (!reload && this.state.weekdayChartData.length > 0) {
                    return
                }
                this.getStatisticsWeekday();
                break
            case chartTabDaily:
                if (!reload && this.state.datasets.length > 0) {
                    return
                }
                this.getStatisticsDaily();
                break;
            case chartTabHourly:
                if (!reload && this.state.heatChartData.length > 0) {
                    return
                }
                this.getStatisticsHourly();
                break;
        }
    }

    render() {
        const { limitExccedFlag } = this.state;

        return (
            <div className="row mt-3">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <header>{this.props.sortBy}</header>
                        </div>
                        <div className="w-100 card-body">
                            {limitExccedFlag && (
                                <div className="notify-message">
                                    You can view insights for a maximum of 5{" "}
                                    {Lookup.display[this.state.displayLabel] ||
                                        this.state.displayLabel}{" "}
                                    at once
                                </div>
                            )}
                            <ul
                                className="nav nav-tabs"
                                id="mTab"
                                role="tablist"
                            >
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.onChartSelected(chartTabWeekday)
                                    }}
                                >
                                    <a
                                        className="nav-link active"
                                        id="weekday-tab"
                                        data-toggle="tab"
                                        href="#weekday"
                                        role="tab"
                                        aria-controls="weekday"
                                        aria-selected="true"
                                    >
                                        {chartTabWeekday}
                                    </a>
                                </li>

                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.onChartSelected(chartTabDaily)
                                    }}
                                >
                                    <a
                                        className="nav-link"
                                        id="daily-tab"
                                        data-toggle="tab"
                                        href="#daily"
                                        role="tab"
                                        aria-controls="daily"
                                        aria-selected="true"
                                    >
                                        {chartTabDaily}
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.onChartSelected(chartTabHourly)
                                    }}
                                >
                                    <a
                                        className="nav-link"
                                        id="hourly-tab"
                                        data-toggle="tab"
                                        href="#hourly"
                                        role="tab"
                                        aria-controls="hourly"
                                        aria-selected="false"
                                    >
                                        {chartTabHourly}
                                    </a>
                                </li>

                                {this.state.chartTab !== chartTabDaily && (
                                    <li
                                        className="nav-item ml-auto"
                                    >
                                        <div className="custom-select-container mr-5">
                                            <select
                                                className="custom-select custom-select-input"
                                                onChange={this.amPmHandler}
                                            >
                                                <option value="am/pm">AM/PM</option>
                                                <option value="am">AM</option>
                                                <option value="pm">PM</option>
                                            </select>
                                        </div>
                                    </li>
                                )}
                            </ul>

                            <div className="tab-content">

                                <div
                                    className="tab-pane fade active show"
                                    id={chartTabWeekday}
                                    role="tabpanel"
                                    aria-labelledby="weekday-tab"
                                >
                                    <div className="card">
                                        <div
                                            className="card-body"
                                            style={{ height: "400px" }}
                                        >
                                            {this.state.chartTab === chartTabWeekday && this.renderHeatMap(this.state.weekdayChartData, this.state.chartKeys, "date")}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id={chartTabDaily}
                                    role="tabpanel"
                                    aria-labelledby="daily-tab"
                                >
                                    {this.state.chartTab === chartTabDaily && this.renderDailyChart()}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id={chartTabHourly}
                                    role="tabpanel"
                                    aria-labelledby="hourly-tab"
                                >
                                    <div className="card">
                                        <div
                                            className="card-body"
                                            style={{ height: "400px" }}
                                        >
                                            {this.state.chartTab === chartTabHourly && (
                                                this.renderHeatMap(this.state.heatChartData, this.state.chartKeys, "date")
                                            )}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomSortCard;
