import React, { Component } from "react";

// libraries
import { ApiList } from "../../config/apiList";
import DateRangePicker from "../common/dateRangePicker";
import SortByFilter from "../common/sortby";
//Component
//import Nav from '../navigation/index'; **
import TopSortCards from "../common/topSortCards";
import ProgressList from "../common/progressList";
import HorizontalRoundedBar from "../charts/horizontalRoundedBar";

import BottomSortCard from "../common/bottomSortCard";
import API from "../../config/api";

import * as utils from "../common/utils";

class Channels extends Component {
    constructor(props) {
        super(props);
        const dateRange = utils.dateRange()

        this.state = {
            topCardData: "",
            sortBy: "playout",
            channelList: [],
            pieData: [],
            topCardDataFirst: [],
            language_collation: "channel_languages",
            ...dateRange
        };
    }

    fetchTopChannels = () => {
        const endpoint = `${ApiList.env}${ApiList.endpoint.topList}/channels/top?since=${this.state.since}&till=${this.state.till}&sortBy=${this.state.sortBy}`;

        API.call(endpoint).then((data) => {
            let top = [];
            let total = 0;
            data.forEach((item, key) => {
                if (key < 10) {
                    top.push({
                        id: item.name,
                        label: item.name,
                        value: item.count,
                    });
                } else {
                    total = total + item.count;
                }
            });

            if (total > 0) {
                top.push({ id: "other", label: "Other", value: total });
            }

            this.setState({
                pieData: top,
                channelList: data,
            });
        });
    };

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="container-fluid">
                        <div className="row justify-content-between  mb-5 py-3 border-bottom">
                            <div className="col-xl-5">
                                <h4>Channels</h4>
                            </div>
                            <div className="col-xl-7">
                                <DateRangePicker
                                    onDateChange={(data) => {
                                        this.setState(data, function () {
                                            this.fetchTopChannels();
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <TopSortCards
                            since={this.state.since}
                            till={this.state.till}
                        />
                        <div className="mt-5">
                            <div className="row justify-content-between">
                                <div className="col-xl-5 d-flex">
                                    <h5>
                                        <span className="page-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="12"
                                                viewBox="0 0 25 12"
                                            >
                                                <path
                                                    fill="#FFF"
                                                    fillRule="nonzero"
                                                    d="M22.302.033c-1.46 0-2.65 1.2-2.65 2.674 0 .538.16 1.038.431 1.458l-2.862 2.889a2.616 2.616 0 0 0-2.935.03l-2.892-2.92c.272-.419.43-.92.43-1.457 0-1.474-1.188-2.674-2.649-2.674-1.46 0-2.65 1.2-2.65 2.674 0 .538.16 1.038.431 1.458L4.094 7.053a2.615 2.615 0 0 0-1.445-.434C1.19 6.62 0 7.82 0 9.293s1.188 2.674 2.65 2.674c1.46 0 2.649-1.2 2.649-2.674 0-.538-.16-1.038-.43-1.458L7.73 4.946a2.616 2.616 0 0 0 2.89 0l2.908 2.935c-.254.41-.401.894-.401 1.412 0 1.474 1.188 2.674 2.65 2.674 1.46 0 2.648-1.2 2.648-2.674 0-.538-.158-1.038-.43-1.458l2.863-2.888c.415.274.911.434 1.444.434 1.46 0 2.65-1.2 2.65-2.674S23.761.033 22.301.033zM2.649 10.861a1.563 1.563 0 0 1-1.554-1.568c0-.865.697-1.569 1.554-1.569.857 0 1.555.704 1.555 1.569s-.698 1.568-1.555 1.568zm6.526-6.585a1.563 1.563 0 0 1-1.554-1.569c0-.865.697-1.568 1.554-1.568.857 0 1.554.703 1.554 1.568 0 .865-.697 1.569-1.554 1.569zm6.601 6.585a1.563 1.563 0 0 1-1.554-1.568c0-.865.697-1.569 1.554-1.569.857 0 1.554.704 1.554 1.569s-.697 1.568-1.554 1.568zm6.526-6.585a1.563 1.563 0 0 1-1.554-1.569c0-.865.697-1.568 1.554-1.568.857 0 1.554.703 1.554 1.568 0 .865-.697 1.569-1.554 1.569z"
                                                />
                                            </svg>
                                        </span>{" "}
                                        Overview
                                    </h5>
                                </div>
                                <div className="col-xl-2">
                                    <SortByFilter
                                        label="Preference"
                                        onClickCallback={(option) => {
                                            this.setState({
                                                sortBy: option,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row justify-content-between mt-3">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <ProgressList
                                                since={this.state.since}
                                                till={this.state.till}
                                                sortBy={this.state.sortBy}
                                                for="channels"
                                                className="primary"
                                            />
                                        </div>
                                        <div className="col-5 custom-flex">
                                            <div className="card">
                                                <div className="card-header">
                                                    <header>By Genre</header>
                                                </div>
                                                <div className="card-body">
                                                    <HorizontalRoundedBar
                                                        till={
                                                            this.state.till
                                                        }
                                                        since={
                                                            this.state.since
                                                        }
                                                        sortBy={
                                                            this.state
                                                                .sortBy
                                                        }
                                                        color={getComputedStyle(
                                                            document.documentElement
                                                        ).getPropertyValue(
                                                            "--color-8"
                                                        )}
                                                        type="genres"
                                                    />
                                                </div>
                                            </div>

                                            <div className="card mt-3">
                                                <div className="card-header clearfix">
                                                    <header className="float-left">
                                                        By Language{" "}
                                                    </header>
                                                    <div className="float-right w-25">
                                                        <SortByFilter
                                                            options={[
                                                                "channel",
                                                                "creative",
                                                            ]}
                                                            label=""
                                                            onClickCallback={(
                                                                option
                                                            ) => {
                                                                this.setState({
                                                                    language_collation:
                                                                        option +
                                                                        "_languages",
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <HorizontalRoundedBar
                                                        till={
                                                            this.state.till
                                                        }
                                                        since={
                                                            this.state.since
                                                        }
                                                        sortBy={
                                                            this.state
                                                                .sortBy
                                                        }
                                                        color={getComputedStyle(
                                                            document.documentElement
                                                        ).getPropertyValue(
                                                            "--color-10"
                                                        )}
                                                        type={
                                                            this.state
                                                                .language_collation
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 pt-3">
                            <BottomSortCard
                                till={this.state.till}
                                since={this.state.since}
                                sortBy={this.state.sortBy}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Channels;
